
import { initializeApp } from "firebase/app";
import {getFirestore} from '@firebase/firestore';


//make a . env file
const firebaseConfig = {
  apiKey: "AIzaSyCt_VtoQXWqG2VGW_si7CNzmOUhwXavFM4",
  authDomain: "mofcalc.firebaseapp.com",
  projectId: "mofcalc",
  storageBucket: "mofcalc.appspot.com",
  messagingSenderId: "758698302005",
  appId: "1:758698302005:web:9e466ca31891ba9b7c6f7c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);