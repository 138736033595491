//import logo from './logo.svg';
import './App.css';
import React, {Component } from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import {db} from './firebase-config';
import {collection, getDocs, updateDoc, doc, addDoc} from 'firebase/firestore';

let val;
let Block;
let chooseBlock;
let errorMessage;
let topStuff;
let calcButton;
let aboutPage;
let counter = 7;
let submitErrMsg;


async function asyncIncrease(){
  await asyncInc();
  counter ++;
  const counterDoc = doc(db, 'bigCollect', 'counter');
  await updateDoc(counterDoc, {counter}); 

}
async function asyncInc(){
      let allData;
      counter = 9;
     const theCollection = collection(db, 'bigCollect');
     const data = await getDocs(theCollection);
      allData = data.docs.map((doc) => ({...doc.data(), id: doc.id}));
      counter = allData[0].counter;
}

let var1,var2,var3,var4,var5,var6,var7,var8,var9,var10,var11,var12,var13,var14,var15 = "outline-secondary";
class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      homePage:true,
      a : 1,
      n:0,
      i:0,
      month:0,
      p:0,
      increaseRate:0,
      decreaseRate:0,
      discrete:true,
      interest:true,
      present:true,
      standard:true,
      increasing:false,
      decreasing: false,
      complex:false,
      increaseCont:false,
      decreaseCont:false,
      email:"",
      comment:"",
      counter:0
    };
  }
  enterA = (Ava) => {
    this.setState({a:Ava.target.value});
  }
  enterN = (Ava) => {
    this.setState({n:Ava.target.value});
  }
  enterIncrease = (Ava) => {
    this.setState({increaseRate:Ava.target.value});
  }

  enterDecrease = (Ava) => {
    this.setState({decreaseRate:Ava.target.value});
  }

  enterI = (Ava) => {
    this.setState({i:Ava.target.value});
  }

  enterP = (Ava) => {

    this.setState({p:Ava.target.value});
  }

  enterM = (Ava) => {
    this.setState({month:Ava.target.value});
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      this.calc();
    }
  }
  enterEmail = (Ava) => {
    submitErrMsg = "";
    this.setState({email:Ava.target.value});
  }
  enterComment = (Ava) => {
    submitErrMsg = "";

    this.setState({comment:Ava.target.value});
  }

  addDoccer = async (email, comment) => {
    if(!email){
      email = "na";
    }

    const theCol = collection(db, 'comments');
    await addDoc(theCol, {email: email, comment:comment});

  }
  submitButton = () => {
    let {email, comment} = this.state;
    
    if((email.includes('@') && email.includes('.') && email.length > 3) || email.length ===0){
      //Email good
      if(comment.length > 100){
        submitErrMsg = "Please leave a short comment."
      }else if(comment.length === 0){
        submitErrMsg = "Please leave a comment."
      }else{
        this.addDoccer(email, comment);
        submitErrMsg = "Success.";
      }
    }else{
      submitErrMsg = "Please Enter a valid email or leave it out."

    }
    this.setState({a:45});
  } 

  calc = () => {
    let {i,n,p,month,discrete,interest,increaseRate,decreaseRate,present,standard,increasing,decreasing, complex,increaseCont,decreaseCont} = this.state;
    i = i/100;
 
    let v = 1/(1+i);
    let d = 1 - v;
    let delta = Math.log(1+i);
 
    let iToIP = p*(Math.pow((1+i), (1/p)) -1);

    let round_a_angle_n = (1-Math.pow(v, n))/i;
    let tremor_a_angle_n = (1-Math.pow(v, n))/d;

   let takeForward = (Math.pow((1+i), n));

   decreaseRate = parseInt(decreaseRate);


    let IAn = (tremor_a_angle_n - n*Math.pow(v, n))/i;

    if(increaseCont){
      month=1;
    }
    

    if(i === 0 || n === 0 | month === 0 || (complex  && parseInt(p) === 0) ){
      errorMessage=<div><p className='errorMsg'>Please fill in all values.</p></div>
      val = '';
      this.setState({a:15});
    }else{
      errorMessage = <div> </div>;

      asyncIncrease();
      if(discrete){
        if(present){
          if(interest){
            if(standard){
              val = month*round_a_angle_n;
            }
            else if(increasing){
              val = (month-increaseRate)*(round_a_angle_n) + increaseRate*IAn;
            }
            else if(decreasing){
              val = (parseInt(month)+parseInt(decreaseRate))*round_a_angle_n - decreaseRate*IAn;
        
            }
            else if(complex){
              val = round_a_angle_n*(i/iToIP)*month;
            }
          }else{
            let moveOne = (1+i);
            if(standard){
              val = month*round_a_angle_n*i/d;
            }
            else if(increasing){
              val = (month-increaseRate)*(round_a_angle_n)*moveOne + moveOne*increaseRate*IAn;
            }
            else if(decreasing){
              val = (parseInt(month)+parseInt(decreaseRate))*round_a_angle_n*moveOne - moveOne*decreaseRate*IAn;
        
            }
            else if(complex){
               val = round_a_angle_n*(i/iToIP)*month*(Math.pow((1+i), (1/p)));
            }

          }

        }else{
          if(interest){

            if(standard){
              val = round_a_angle_n*month*takeForward;
            }
            else if(increasing){
              val = ((month-increaseRate)*(round_a_angle_n) + increaseRate*IAn)*takeForward;
            }
            else if(decreasing){
              val = takeForward*( (parseInt(month)+parseInt(decreaseRate))*round_a_angle_n - decreaseRate*IAn);
        
            }
            else if(complex){
              val = round_a_angle_n*(i/iToIP)*takeForward*month;
            }
          }else{
            let moveOne = (1+i);
            if(standard){
              val = round_a_angle_n*month*takeForward*moveOne;
            }
            else if(increasing){
              val = moveOne*takeForward*((month-increaseRate)*(round_a_angle_n) + increaseRate*IAn);
            }
            else if(decreasing){
              val = takeForward*moveOne*( (parseInt(month)+parseInt(decreaseRate))*round_a_angle_n - decreaseRate*IAn);
        
            }
            else if(complex){
              val = round_a_angle_n*(i/iToIP)*takeForward*month*moveOne;
            }


          }

        }

      }else{
       if(present){
        if(standard){
          val = month*round_a_angle_n*(i/delta);
        }
        else if(increasing){
          val = (month-increaseRate)*(round_a_angle_n)*(i/delta) + increaseRate*IAn*(i/delta);
        }
        else if(increaseCont){
          val = parseInt(increaseRate)*((round_a_angle_n*(i/delta)) - n*Math.pow(v, n))/delta;
        }
        else if(decreasing){
          val = (parseInt(month)+parseInt(decreaseRate))*(round_a_angle_n)*(i/delta) - decreaseRate*IAn*(i/delta);
        }else if(decreaseCont){
          val = month*round_a_angle_n*(i/delta)-parseInt(decreaseRate)*((round_a_angle_n*(i/delta)) - n*Math.pow(v, n))/delta;
        }

       }else{
          if(standard){
            val = month*round_a_angle_n*(i/delta)*takeForward;
          }
          else if(increasing){
            val = takeForward*((month-increaseRate)*(round_a_angle_n)*(i/delta) + increaseRate*IAn*(i/delta));
          }
          else if(increaseCont){
            val = takeForward*parseInt(increaseRate)*((round_a_angle_n*(i/delta)) - n*Math.pow(v, n))/delta;
          }else if(decreaseCont){
            val = takeForward*(month*round_a_angle_n*(i/delta)-parseInt(decreaseRate)*((round_a_angle_n*(i/delta)) - n*Math.pow(v, n))/delta);
          }
          else if(decreasing){
          val = takeForward*((parseInt(month)+parseInt(decreaseRate))*(round_a_angle_n)*(i/delta) - decreaseRate*IAn*(i/delta));
        }
        }
      }
      let ansString = val.toFixed(9);
      let sides = ansString.split('.');
      let right = sides[1][0] + sides[1][1] +sides[1][2] + " " +sides[1][3] +sides[1][4] +sides[1][5] + " " +sides[1][6] +sides[1][7] +sides[1][8];
      let counter = 0;
      let left = "•";
      for(let i = sides[0].length; i > 0;i--){
        if(counter % 3 === 0 && counter >0){
          left = " " + left;
        }
        left = sides[0][i-1] + left;
        counter ++;
        
      }
      val = left + right;
    }
  

    this.setState({a:100});
  }
  goStandardDis = () => {
    val = '';
    var7 = "outline-secondary active"; 

    var8 = "outline-secondary";
    var9 = "outline-secondary";
    var10 = "outline-secondary";

    this.setState({
      increasing:false,
      decreasing: false,
      complex:false,
      increaseCont:false,
      decreaseCont:false,      
      standard:true, 
      n:0,
      i:0,
      month:0,
      p:0
    });


  }

  goIncreaseDis =() => {
    val = '';
    var8 = "outline-secondary active"; 

    var7 = "outline-secondary";
    var9 = "outline-secondary";
    var10 = "outline-secondary";

    this.setState({
      standard:false,
      decreasing: false,
      complex:false,
      increaseCont:false,
      decreaseCont:false,      

      increasing:true, 
      n:0,
      i:0,
      month:0,
      p:0
    });


  }

  goDecreaseDis = () => {
    val = '';
    var9 = "outline-secondary active"; 

    var7 = "outline-secondary";
    var8 = "outline-secondary";
    var10 = "outline-secondary";

    this.setState({
      standard:false,
      increasing: false,
      complex:false,
      increaseCont:false,
      decreaseCont:false,      

      decreasing:true, 
      n:0,
      i:0,
      month:0,
      p:0
    });

  }

  goComplexDis = () => {
    val = '';
    var10 = "outline-secondary active"; 

    var7 = "outline-secondary";
    var8 = "outline-secondary";
    var9 = "outline-secondary";

    this.setState({
      standard:false,
      increasing: false,
      decreasing:false,
      increaseCont:false,
      decreaseCont:false,    

      complex:true, 
      n:0,
      i:0,
      month:0,
      p:0
    });

  }

  goDiscrete = () => {
    val = '';
    var1 = "outline-secondary active";
    var2 = "outline-secondary";

    var3 = "outline-secondary active";
    var4 = "outline-secondary";


    this.setState({discrete:true});
  }
   goCont = () => {
    val = '';
    var1 = "outline-secondary";
    var2 = "outline-secondary active";
  var3 = "dark";
    var4 = "dark";

    this.setState({discrete:false});
  }

  goArrear = () => {
    val = '';
     let {discrete} = this.state;

     if(discrete){
      var3 = "outline-secondary active";
      var4 = "outline-secondary";
    }

    this.setState({interest:true});
  }

  goAdvance = () => {
    val = '';
    let {discrete} = this.state;

    if(discrete){
      var4 = "outline-secondary active";
      var3 = "outline-secondary";
    }else{

    }
    this.setState({interest:false});
  }


  goPres = () => {
    var5 = "outline-secondary active";
    var6 = "outline-secondary";
    this.setState({present:true});
  }

  goFuture = () => {
    var6 = "outline-secondary active";
    var5 = "outline-secondary";
    this.setState({present:false});
  }


  goStandardCont = () => {
    val = '';
    var11 = "outline-secondary active"; 

    var12 = "outline-secondary";
    var13 = "outline-secondary";
    var14 = "outline-secondary";
    var15 = "outline-secondary";

    this.setState({
      standard:true,

      decreasing: false,
      complex:false,
      increaseCont:false,
      decreaseCont:false,      
      increasing:false, 
      n:0,
      i:0,
      month:0,
      p:0

    });
  }
  goIncreaseCont = () => {
    val = '';
    var12 = "outline-secondary active"; 

    var11 = "outline-secondary";
    var13 = "outline-secondary";
    var14 = "outline-secondary";
    var15 = "outline-secondary";

    this.setState({
      standard:false,
      decreasing:false ,
      complex:false,
      increaseCont:false,
      decreaseCont:false,      
      increasing:true, 
      n:0,
      i:0,
      month:0,
      p:0

    });
  }
  goDecreaseCont = () => {
    val = '';
    var14 = "outline-secondary active"; 
    var11 = "outline-secondary";
    var12 = "outline-secondary";
    var13 = "outline-secondary";
    var15 = "outline-secondary";
    this.setState({
      standard:false,
      decreasing:true ,
      complex:false,
      increaseCont:false,
      decreaseCont:false,      
      increasing:false, 
      n:0,
      i:0,
      month:0,
      p:0
    });
  }
  goDecreaseContCont = () => {
    val = '';
    var15 = "outline-secondary active"; 
    var11 = "outline-secondary";
    var12 = "outline-secondary";
    var13 = "outline-secondary";
    var14 = "outline-secondary";
    this.setState({
      standard:false,
      decreasing:false,
      complex:false,
      increaseCont:false,
      decreaseCont:true,      
      increasing:false, 
      n:0,
      i:0,
      month:0,
      p:0
    });
  }
  goIncreaseContCont = () => {
    val = '';
    this.setState({month:1});
    var13 = "outline-secondary active"; 
    var11 = "outline-secondary";
    var12 = "outline-secondary";
    var14 = "outline-secondary";
    var15 = "outline-secondary";
    this.setState({
      standard:false,
      decreasing:false,
      complex:false,
      increaseCont:true,
      decreaseCont:false,      
      increasing:false, 
      n:0,
      i:0,
      month:0,
      p:0
    });
  }

  moveHome = () =>{
    this.setState({homePage:true});
  }
  moveOut = () => {
    this.setState({homePage:false});
  }

  componentDidMount(){

    asyncInc();
    


    var1 = "outline-secondary active";
    var3 = "outline-secondary active";
    var5 = "outline-secondary active";
    var7 = "outline-secondary active";
    var11 = "outline-secondary active";


    var2 = "outline-secondary";
    var4 = "outline-secondary";
    var6 = "outline-secondary";
    var8 = "outline-secondary";
    var9 = "outline-secondary";
    var10 = "outline-secondary";
    var12 = "outline-secondary";
    var13 = "outline-secondary";
    var14 = "outline-secondary";
    var15 = "outline-secondary";

    this.setState({a:3});
  }




  render() {
    let {homePage,discrete,interest,present, standard,increasing,decreasing,complex,increaseCont, decreaseCont} = this.state;
    if(!homePage){
      aboutPage = <div>
            <h3>This Calculator has solved:</h3>
              <h2>{counter}</h2>
              <h3>Annuities</h3>
              <p>Don't believe me? Go back and enter a problem and recheck this counter.</p>
      
        <hr/>
        <br/>
        <Container>
          <Row>
            
            <Col className='block'>
             Have somthing to say?
            <hr/>

            <div className='leftBlock'>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                  <Form.Label>Email address (Optional)</Form.Label>
                  <Form.Control type="email" placeholder="name@example.com" onChange={this.enterEmail} />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Comment</Form.Label>
                  <Form.Control as="textarea" onChange={this.enterComment} placeholder="Nine decimals is too few. I only work to 41   :(" rows={3} />
                </Form.Group>
              </Form>

            </div>
            <Button className='contt' variant="secondary" onClick={this.submitButton}>Submit</Button>{' '}
            {submitErrMsg}
            </Col>

            <Col className='block'>
            About us
            <hr/>
            <div className='leftBlock'>
            I am just a student who has had way too much experience 
            with struggling through calculating difficult Mathematics of Finances 
            problems. <br/>
            I hope this helps to make the subject more interesting and make the problems
            less about the tedious calculations and more about the overarching ideas.
            <br/>
            <br/>
            By the way:
            <br/>
            <div>Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>

            </div>
            </Col>

          </Row>
        </Container>

      </div>


      calcButton = <div></div>;
      topStuff = <div></div>;
      Block = <div></div>;
      chooseBlock = <div></div>
      val = '';

    }else{
      aboutPage = <div></div>


    calcButton = <div> 
    <br/>
    <div className="d-grid gap-2">
  <Button className='bord' variant="outline-primary" onClick={this.calc} size="lg">
    Calculate
  </Button>

</div>
      
    </div>

    topStuff = <div> 
    <Container>
      <Row> 
      <br/> 
      <Form>
      <Col ><Button onClick={this.goDiscrete} variant={var1}  className='cont'>Discrete</Button>{' '}
      <Button  className='cont' onClick={this.goCont} variant={var2}>Continuous</Button>{' '}</Col>
      </Form>
      </Row>
      </Container>
      <Container >
      <Row> 
     
      <Col ><Button className='cont' onClick={this.goArrear} variant={var3}>Annuity In Arrear</Button>{' '}
      <Button className='cont' onClick={this.goAdvance} variant={var4}>Annuity In Advance</Button>{' '}</Col>
      </Row>
      </Container>
      <Container>
      <Row> 
     
      <Col ><Button className='cont' onClick={this.goPres} variant={var5}>Present Value</Button>{' '}
      <Button  className='cont' onClick={this.goFuture} variant={var6}>Future Value</Button>{' '}</Col> 
      <hr/>
      </Row>
      </Container>
      </div>
    if(discrete){
        if(standard){
          Block = <div className='formSpace'>
          <br/>
          <i className='big'>Simply evaluates a series of payments at a single point in time.</i>
          <br/>
          <br/>
          <Form><
          Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Period Installment</Form.Label>
          <Form.Control type="number" onKeyDown={this._handleKeyDown} placeholder="Enter Installment" onChange={this.enterM} />
            
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Number of Periods</Form.Label>
          <Form.Control type="number" onKeyDown={this._handleKeyDown} placeholder="Enter Number Periods" onChange={this.enterN}/>
            
          </Form.Group><Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Interest Rate as a Percent</Form.Label>
          <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            
          </Form.Group></Form></div>
        }
        if(increasing){
          Block = <div className='formSpace'><Form>
          <br/>
          <i className='big'>Simply evaluates a series of payments - which are incresaing arithmetically - at a single point in time.</i>
          <br/>
          <br/>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Increase Per Period</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown} placeholder="Enter Increase Per Period" onChange={this.enterIncrease} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate as a Percent</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate Percent" onChange={this.enterI}/>
            </Form.Group>
          </Form></div>
        }
        if(decreasing){
          Block = <div className='formSpace'><Form>
          <br/>
          <i className='big'>Simply evaluates a series of payments - which are decreasing arithmetically - at a single point in time.</i>
          <br/>
          <br/>
          
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Decrease Per Period</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Decrease Per Period" onChange={this.enterDecrease} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate as a Percent</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate Percent" onChange={this.enterI}/>
            </Form.Group>
          </Form></div>
        }
        if(complex){
          Block = <div className='formSpace'><Form>
          <br/>

          <i className='big'>Simply evaluates a series of payments where the interest is quoted as an effective rate but there are multiple compounding periods per year.</i>
          <br/>
          <br/>
          
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Payment Per Annum</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Payment Per Annum" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Years</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Years" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate Per annum as a Percent</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter Effective Yeary Interest Rate Percent" onChange={this.enterI}/>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Compound Periods Per Year</Form.Label>
            <Form.Control type="number" onChange={this.enterP} onKeyDown={this._handleKeyDown} placeholder="Enter Number of Compound Periods Per Year"/>
            </Form.Group>
          </Form></div>
        }
    }else{
      if(interest){
        if(standard){
          Block = <div className='formSpace'>

          <br/>

          <i className='big'>Evaluates a continuous series of payments made at infinitesimally small intervals and at a interest rate that is compouned continuously. 
          Please enter the effective yearly interest rate and the appropriate rate is calculated.
          </i>
          <br/>
          <br/>

          
          <Form><
          Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Rate of Payment Per Period</Form.Label>
          <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Installment" onChange={this.enterM} />
            
          </Form.Group><Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Number of Periods</Form.Label>
          <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Interest Rate as a Percent</Form.Label>
          <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            
          </Form.Group></Form></div>
        }
        if(increasing){
          Block = <div className='formSpace'>

          <br/>

          <i className='big'>The same continuous conditions that apply to the standard continuous annuity.
           However, the rate of payment in year r is the product of r and the increase per period.  
          </i>
          <br/>
          <br/>
          
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Increase Per Period</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Increase Per Period" onChange={this.enterIncrease} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate as a Percent</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>
          </Form></div>
        }
        if(decreasing){
          Block = <div className='formSpace'>
          <br/>
          <i className='big'>The same continuous conditions that apply to the standard continuous annuity.
           However, the rate of payment in year r is the first payment minus the product of (r-1) and the increase per period.  
          </i>
          <br/>
          <br/>

          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Decrease Per Period</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Decrease Per Period" onChange={this.enterDecrease} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate as a Percent</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>
          </Form></div>
        }
        if(increaseCont){
          Block = <div className='formSpace'>
          <br/>
          <i className='big'>The same continuous conditions that apply to the standard continuous annuity.
           However, the rate of payment at time r is the product of r and the rate of increase.  
          </i>
          <br/>
          <br/>

          <Form>


            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Rate of Increase</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Rate of Increase" onChange={this.enterIncrease}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Effective Interest Rate</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>

          </Form></div>
        }
        if(decreaseCont){
          Block = <div className='formSpace'>
          <br/>
          
          <i className='big'>The same continuous conditions that apply to the standard continuous annuity.
           However, the rate of payment at time r is the first payment minus the product of r and the rate of decrease per period.  
          </i>
          <br/>

          <br/>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Rate of Decrease of Payment</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown}  placeholder="Enter Rate of Decrease of Payment" onChange={this.enterDecrease}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Effective Interest Rate</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>

          </Form></div>
        }
      }else{
        if(standard){
            Block = <div className='formSpace'>
            <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Period Installment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Installment" onChange={this.enterM} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate as a Percent</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>
            </Form></div>
        }
        if(increasing){
            Block = <div className='formSpace'><Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Increase Per Period</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Increase Per Period" onChange={this.enterIncrease} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>
            </Form></div>
        }
        if(decreasing){
            Block = <div className='formSpace'><Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Decrease Per Period</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Decrease Per Period" onChange={this.enterDecrease} />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>First Payment</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter First Payment" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Periods</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter Number of Periods" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Interest Rate as a Percent</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>
            </Form></div>
        }
        if(complex){
            Block = <div className='formSpace'><Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Payment Per Annum</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Payment per Annum" onChange={this.enterM}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Years</Form.Label>
            <Form.Control type="number" onKeyDown={this._handleKeyDown}  placeholder="Enter Number of Years" onChange={this.enterN}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Effective Interest Rate</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter Interest Rate" onChange={this.enterI}/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Number of Coumpound Periods</Form.Label>
            <Form.Control type="number"  onKeyDown={this._handleKeyDown} placeholder="Enter Number of Compound Periods" onChange={this.enterP}/>
            </Form.Group>
            </Form></div>
        }
      }

    }

    if(this.state.discrete === true){
      chooseBlock = <div> <Container>
      <Row> 
      <Col ><Button onClick={this.goStandardDis} variant={var7}>Standard Annuity</Button>{' '}</Col>
      <Col ><Button onClick={this.goIncreaseDis} variant={var8}>Increasing</Button>{' '}</Col>
      <Col><Button onClick={this.goDecreaseDis} variant={var9}>Decreasing</Button>{' '}</Col>       
      <Col><Button onClick={this.goComplexDis} variant={var10}>Complex</Button>{' '}</Col>        
      </Row>
      </Container></div>
    }else{
       chooseBlock = <div> <Container>
      <Row> 
      <Col ><Button onClick={this.goStandardCont} variant={var11}>Standard Annuity</Button>{' '}</Col>
      <Col ><Button onClick={this.goIncreaseCont} variant={var12}>Increasing Discretly</Button>{' '}</Col>
      <Col ><Button onClick={this.goIncreaseContCont} variant={var13}>Increasing Continously</Button>{' '}</Col>
      <Col><Button onClick={this.goDecreaseCont} variant={var14}>Decreasing Discretly</Button>{' '}</Col>       
      <Col ><Button onClick={this.goDecreaseContCont} variant={var15}>Decreasing Continously</Button>{' '}</Col>
      
      </Row>
      </Container></div>
    }
  }

    return(
      <div>

      <Container className="p-3">
      <Navbar bg="dark" variant="dark">
      <Container>
      <Navbar.Brand href="#home" onClick={this.moveHome}>MoF Calculator</Navbar.Brand>
      <Nav className="me-auto">
      <Nav.Link href="#home" onClick={this.moveHome}>Calculator</Nav.Link>
      <Nav.Link href="#about" onClick={this.moveOut}>About</Nav.Link>
      </Nav>
      </Container>
      </Navbar> 
      <br />

      <br/>

      <div className='n2'><h3><i>Complex Annuity Calculator</i></h3></div>
      <br/>

      <Container>
      <Row>
      <Col>

      <Col className='block'>
      <br/>
      {aboutPage}
      {topStuff}
      
       {chooseBlock}  
       

  
      {Block}

      {calcButton}



      <br/>
      <i>

      {errorMessage}
      
      <h1>{val}</h1>
      

      </i>
      </Col>
      

      </Col>
      </Row>
      </Container>

      </Container>
      </div>
      );  
  }
}
export default App;
